<template>
    <div class="relative !z-20" v-motion-fade>
        <div class="dark:bg-background w-full h-full overflow-y-visible z-20 relative" :class="{'!overflow-y-hidden max-h-[calc(100vh-50px)]': route?.params?.type == 'project'}" ref="dropZoneRef">
            <div class="">
                <slot />
            </div>
            <ChatDialog v-if="user && route?.params?.project && activePanel" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { Loader2 } from "lucide-vue-next";
import { useDropZone } from '@vueuse/core';
import { isEqual } from 'lodash-es'
const dropZoneRef = ref<HTMLDivElement>()



const userStore = useUserStore();
const uiStore = useUiStore();
const projectStore = useProjectStore();
const dockStore = useDockStore();

const { selectedTeam, plan, teams } = storeToRefs(userStore);
const { setTeams, setPlan, setSelectedTeam, setUser } = userStore;
const { setSelectedProject } = projectStore;

const user = useSupabaseUser()
watch(user, (newUser, oldUser) => {
  if (newUser?.id !== oldUser?.id) {
    // Clear all project-related cache
    
    Object.keys(useNuxtApp().payload.data).forEach(key => {
      if (key.startsWith('projects-')) {
        delete useNuxtApp().payload.data[key];
      }
    });
  }
}, { immediate: true });

const { activePanel } = storeToRefs(dockStore);

const { menu } = storeToRefs(uiStore);
const { projects } = storeToRefs(projectStore);
const { setPlans, setProjectImages, toggleBottomSheet } = uiStore;



const route = useRoute();
const queryParams = route.query;

const headers = useRequestHeaders(["cookie"]);


let realtimeChannels: RealtimeChannel[] = []

const getRealtimeChannel = (table: string, schema: string, callback: () => void) => {
    const channelId = `${schema}_${table}_${Math.random().toString(36).slice(2, 11)}`
    return client
        .channel(channelId)
        .on(
            "postgres_changes",
            { event: "*", schema: schema, table: table },
            callback
        )
        .subscribe();
};

onMounted(() => {
    realtimeChannels = [
        getRealtimeChannel("groups", "public", async () => {
            console.log('detected new group')
            await refreshFetchedTeams()
        }),
        getRealtimeChannel("users", "auth", refreshFetchedUser)
    ]
})

onUnmounted(() => {
    realtimeChannels.forEach(channel => {
        client.removeChannel(channel)
    })
})

const { data: fetchedPlans, error: fetchedPlansError, refresh: refreshPlans } = await useAsyncData(
    'fetched-plans',
    async () => {
        const { data } = await useFetch("/api/plans/list", {
            method: "GET",
            headers,
        });
        return data;
    }
);

watch(fetchedPlans, (newPlans) => {
    // console.log('new plans', newPlans)
    if (newPlans) {
        // console.log('setting new plans', newPlans)
        setPlans(newPlans.value?.products)
    }
}, { immediate: true })

const client = useSupabaseClient();

const test = ref(null)

const testfunc = async () => {
    const { data } = await useFetch('/api/test', { headers })
    test.value = data
}

function onOver(files: File[] | null) {
    if (route.params?.team && !route.params.project) {
        toggleBottomSheet(true)
    }
}

const { isOverDropZone } = useDropZone(dropZoneRef, {
    onOver,
    // specify the types of data to be received.
    // dataTypes: ['image/jpeg']
})



const { pending: pendingUser, data: fetchedUser, refresh: refreshFetchedUser } = await useFetch("/api/users/user");

watch(fetchedUser, (newUser: any) => {
    if (newUser?.id) {
        setUser(newUser)
    }
}, { immediate: true })


const personalTeam = computed(() => {
    return {
        id: user.value?.id,
        metadata: {
            name: user.value?.email || 'Personal',
            type: "Personal",
            description: "Personal account",
        },
    };
});




watch(route, (newRoute, oldRoute) => {
    if (!isEqual(newRoute, oldRoute)) {
        if (teams && newRoute.params.team) {
            const routeTeam = teams.value?.find(t => t.id == newRoute.params.team)
            setSelectedTeam(routeTeam)
        }
        
        if (projects && newRoute.params.project) {
            const routeProject = projects.value?.find(t => t.id == newRoute.params.project)
            setSelectedProject(routeProject)
        }
        
        if (newRoute.params.type == 'user') {
            // console.log('route is a personal user one', personalTeam)
            setSelectedTeam(personalTeam.value)
        }
        
    } else{
        console.log('route is the same', newRoute, oldRoute)
    }
})




const { data: userPlan, error, pending: pendingUserPlan } = await useLazyFetch("/api/users/plan", {
    method: "POST",
    headers
})

watch(userPlan, async (newPlan) => {
    if (newPlan) {
        setPlan(newPlan)
    } else {
        await refreshNuxtData('userPlan')
    }
}, {immediate: true})


const { data: fetchedTeams, error: teamsError, refresh: refreshFetchedTeams } = await useLazyAsyncData('fetched-teams', async () => {
    return await client.from("groups").select("*").in("id", Object.keys(user?.value?.app_metadata?.groups ?? {}))
})

watch(fetchedTeams, async (newTeams: any) => {
    if (newTeams?.data?.length !== 0) {
        setTeams(newTeams?.data)
    }
}, { immediate: true })
















</script>

