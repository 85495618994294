<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { CSSProperties } from "vue";
import { theme } from '#tailwind-config'
import { useChatStyles } from '~/composables/useChatStyles'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { ChevronsUpDown } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
import { useValidationStore } from '@/stores/validationStore'
import { useValidation } from '@/composables/useValidation'
import { useTools } from '@/composables/tools/useTools'

const uiStore = useUiStore()
const chatStore = useChatStore()
const dockStore = useDockStore()
const validationStore = useValidationStore()
const { validationIcons } = useValidation()

const {activePanel, includeDataInChat} = storeToRefs(dockStore)
const { history: storeHistory, tosAccepted } = storeToRefs(chatStore)

const {setIncludeDataInChat, setActivePanel, setSwitchToPanel} = dockStore

const {setHistory, acceptTos} = chatStore


const { chatDialogOpen, chatDialogPinned, windowHeight } = storeToRefs(uiStore)
const { toggleChatDialog, togglePinChatDialog } = uiStore
const motions = useMotions()

const chatDialogRef =  ref<HTMLElement | null>(null)
const toolbarRef = ref(null)
const deepChatRef = ref<HTMLElement | null>(null)

const history = ref([])
const isLoaded = ref(false)
const route = useRoute()
const user = useSupabaseUser()
const colorMode = useColorMode()
const base_host = useRuntimeConfig().public.base_host
const introMessage = ref({ text: "Hello, how can I help you?", role: "assistant" })
const randomKey = ref(0)

const projectData = useProjectData();
const { getProjectAnalysis, getPanelProps } = projectData;

const { tools } = useTools()

// const loadPanelProps = async () => {
//   let panelProps = useNuxtData(`projects-panelprops-${owner_id}-${project_id}`)?.value;

//   if (!panelProps) {
//     panelProps = await refreshNuxtData(`projectpanels-${owner_id}-${project_id}`);
//   }

//   if (!panelProps) {
//     panelProps = await getPanelProps(project_id, owner_id, owner_type, true);
//   }

//   return { data: null, status: panelProps?.status, error: panelProps?.error }

// }

const owner_id = route.params.type == 'user' ? user.value.id : route.params.team
const project_id = route.params.project
const owner_type = route.params.type

// const { data: panelProps, error: panelPropsError, status: panelPropsStatus } = await loadPanelProps()
// if (panelProps?.data) {
  
// }

const currentTool = computed(() => {
  
  // let tool = tools.find(tool => tool.uid === panelState?.value?.[activePanel?.value]?.data.tool_uid ||  panelState?.value?.[activePanel?.value]?.tool_uid)?.label
  // return tool
})

watch(colorMode, async (mode) => {
  randomKey.value++

})

onClickOutside(chatDialogRef, () => {
  if (!chatDialogPinned.value) {
    toggleChatDialog(false)
  }
}, { ignore: [toolbarRef] })


const include = computed({
  get: () => includeDataInChat.value,
  set: (value) => setIncludeDataInChat(value)
})


const {
  chatStyle,
  inputAreaStyle,
  textInput,
  submitButtonStyles,
  auxiliaryStyle,
  avatars,
  messageStyles,
} = useChatStyles()

const loadChat = async () => {
  if (import.meta.client) {
    await import('deep-chat')
    isLoaded.value = true
  }
}


useNuxtApp().hook('app:mounted', async () => {  
  await loadChat
})

watch(history, (h) => {
  // console.log('watch: history', h)
  setHistory(h, route.params.project as string || 'default')
})


const handleNewMessage = (message) => {
  if (!message.detail.isInitial) {
    history.value.push(message.detail.message);
  }
}

function zipRowsAndColumns(data) {
  const { rows, columns } = data;
  
  // Only take essential column data
  const essentialColumns = columns.map(column => ({
    field: column.field,
    title: column.title || column.field
  }))
  
  // Create minimal row objects with only the field values
  return {
    columns: essentialColumns,
    rows: rows.map(row => {
      const zippedRow = {};
      essentialColumns.forEach(column => {
        zippedRow[column.field] = row[column.field];
      });
      return zippedRow;
    })
  }
}

const selectedSources = shallowRef(new Set<string>())


// Store for panel data received via events
const panelDataStore = ref(new Map())

// Subscribe to panel events
onMounted(() => {
  

  

  // Cleanup subscriptions
  onUnmounted(() => {
  
  
  })
})

// Modify toggleSource to use event-based data
const toggleSource = (sourceId: string) => {
  const newSet = new Set(selectedSources.value)
  const source = availableDataSources.value.find(s => s.id === sourceId)
  const projectValidations = validationStore.allValidations[route.params.project as string]
  
  if (newSet.has(sourceId)) {
    newSet.delete(sourceId)
    console.log('Removed from chat:', {
      type: source?.type,
      name: source?.name,
      id: sourceId
    })
  } else {
    newSet.add(sourceId)
    if (source?.type === 'tool') {
      // Get data from event store instead of panel state
      // const panelId = Object.keys(panelState.value || {}).find(
      //   id => panelState.value[id].data?.tool_uid === sourceId
      // )
      // const eventData = panelId ? panelDataStore.value.get(panelId) : null
      
      let chatData
      if (sourceId === '28840ba0-1bd2-4ceb-93fa-322131ad1659') { // Map tool
        chatData = eventData?.type === 'map' ? eventData.data : null
      } else if (sourceId === tools.find(t => t.id === 'plantgrid')?.uid) { // Plant Grid
        if (eventData?.type === 'grid') {
          chatData = {
            columns: eventData.data.columnDefs.map(col => ({
              field: col.field,
              headerName: col.headerName,
              type: col.type
            })),
            rows: eventData.data.rowData
          }
        }
      }
      
      console.log('Added tool to chat:', {
        type: 'tool',
        name: source.name,
        id: sourceId,
        data: chatData
      })
    } else if (source?.type === 'validation') {
      const validationData = projectValidations?.[sourceId]
      const truncatedData = truncateValidationData(sourceId, validationData)
      console.log('Added validation to chat:', {
        type: 'validation',
        name: source.name,
        id: sourceId,
        data: truncatedData
      })
    }
  }
  selectedSources.value = newSet
  setIncludeDataInChat(newSet.size > 0)
}

const truncateArrayRandomly = (arr: any[], maxLength: number = 10) => {
  if (!Array.isArray(arr) || arr.length <= maxLength) return arr;
  const shuffled = [...arr].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, maxLength);
};

const truncateValidationData = (validationType: string, data: any) => {
  if (!data) return data;
  
  const truncated = { ...data };
  
  if (validationType === 'biosecurityStatus') {
    // For each species in the result
    Object.keys(truncated.result || {}).forEach(species => {
      if (truncated.result[species].hosts) {
        truncated.result[species].hosts = truncateArrayRandomly(truncated.result[species].hosts);
      }
      if (truncated.result[species].pests) {
        truncated.result[species].pests = truncateArrayRandomly(truncated.result[species].pests);
      }
    });
  } else if (validationType === 'availabilityStatus') {
    // For each species in the result
    Object.keys(truncated.result || {}).forEach(species => {
      if (truncated.result[species].suppliers) {
        // Map suppliers to only include needed fields
        truncated.result[species].suppliers = truncated.result[species].suppliers.map(supplier => ({
          botanical_name: supplier.botanical_name,
          common_name: supplier.common_name,
          price: supplier.price,
          quantity: supplier.quantity,
          size: supplier.size,
          isAvailable: supplier.isAvailable,
          supplier: {
            name: supplier.supplier.name,
            state: supplier.supplier.state,
            address: supplier.supplier.address
          }
        }));
      }
      // Keep only essential species-level fields
      truncated.result[species] = {
        isAvailable: truncated.result[species].isAvailable,
        suppliers: truncated.result[species].suppliers
      };
    });
  } else if (validationType === 'diversityStatus') {
    // Keep essential diversity validation data
    truncated.result = {
      isValid: truncated.result?.isValid,
      speciesCount: truncated.result?.speciesCount,
      criteria: truncated.result?.criteria,
      genusDistribution: truncated.result?.genusDistribution,
      familyDistribution: truncated.result?.familyDistribution,
      unmatchedSpecies: truncated.result?.unmatchedSpecies
    };
  }
  
  return truncated;
};

const createChatRequest = async (body: any) => {
  console.log('createChatRequest')
  const projectValidations = validationStore.allValidations[route.params.project as string]
  
  let analysisData = {} as Record<string, any>
  
  // Include data from all selected tools
  Object.values(panelState.value || {}).forEach(panel => {
    if (panel.data?.tool_uid && selectedSources.value.has(panel.data.tool_uid)) {
      const tool = tools.find(t => t.uid === panel.data.tool_uid)
      if (!tool) {
        console.error('Tool not found:', panel.data.tool_uid)
        return
      }

      // Special handling for plant schedule data
      if (panel.data?.tool_uid === '4447bd6c-9ade-47c3-891b-2d88576a2ec7') {
        try {
          // Use the columnDefs and rowData directly from panel data
          analysisData[tool.id] = {
            columns: panel.data.columnDefs?.map(col => ({
              field: col.field,
              headerName: col.headerName,
              type: col.type
            })) || [],
            rows: panel.data.rowData || []
          }
        } catch (e) {
          console.error('Error formatting plant schedule data:', e)
        }
      }
      // Special handling for map data
      else if (panel.data?.tool_uid === '28840ba0-1bd2-4ceb-93fa-322131ad1659') {
        const mapData = panel.data?.output
        analysisData[tool.id] = {
          occurrences: mapData?.occurrences || [],
          visibleLayers: mapData?.visibleLayers || [],
          bounds: mapData?.bounds,
          center: mapData?.center,
          zoom: mapData?.zoom,
          species: mapData?.species || [],
          taxon: mapData?.taxon || []
        }
      }
      // Default handling for other tools
      else {
        analysisData[tool.id] = toRaw(panel.data?.output)
      }
    }
  })
  
  // Include validation data
  if (projectValidations) {
    if (projectValidations.nameStatus && selectedSources.value?.has('nameStatus')) {
      analysisData.nameValidation = toRaw(truncateValidationData('nameStatus', projectValidations.nameStatus))
    }
    if (projectValidations.biosecurityStatus && selectedSources.value?.has('biosecurityStatus')) {
      analysisData.biosecurity = toRaw(truncateValidationData('biosecurityStatus', projectValidations.biosecurityStatus))
    }
    if (projectValidations.availabilityStatus && selectedSources.value?.has('availabilityStatus')) {
      analysisData.availability = toRaw(truncateValidationData('availabilityStatus', projectValidations.availabilityStatus))
    }
    if (projectValidations.diversityStatus && selectedSources.value?.has('diversityStatus')) {
      analysisData.diversity = toRaw(truncateValidationData('diversityStatus', projectValidations.diversityStatus))
    }
  }
  
  return {
    ...body,
    additionalData: analysisData,
    toolType: currentTool.value?.toLowerCase()
  }
}


const handler = async (body, signals) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const handleError = (message: string) => {
    // Check for common error patterns and provide friendly messages
    if (message.includes('Request too large')) {
      return 'The selected data is too large. Please try selecting fewer items or sending a shorter message.';
    }
    if (message.includes('rate-limits')) {
      return 'We\'re experiencing high traffic. Please wait a moment and try again.';
    }
    if (message.toLowerCase().includes('error:')) {
      return 'Something went wrong. Please try again in a moment.';
    }
    return message;
  };

  try {
    if (includeDataInChat.value == true) {
      body = await createChatRequest(body);
    }

    const response = await fetch('/api/sse', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      signal,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    signals.onOpen();

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value, { stream: true });
      const messages = chunk.split('\n\n').filter(Boolean);

      for (const message of messages) {
        if (message.startsWith('data: ')) {
          try {
            const parsed = JSON.parse(message.slice(6));
            if (parsed.text) {
              // Intercept error messages and replace with friendly ones
              const text = handleError(parsed.text);
              signals.onResponse({ text });
            }
            if (parsed.end) {
              signals.onClose();
              return;
            }
          } catch (error) {
            console.error('Error parsing message:', error);
          }
        }
      }
    }

    signals.onClose();
  } catch (e) {
    if (e.name === 'AbortError') {
      console.log('Fetch aborted');
    } else {
      console.error('Handler error:', e);
      signals.onResponse({ 
        text: 'Sorry, I\'m having trouble connecting right now. Please try again in a moment.' 
      });
    }
  } finally {
    signals.onClose();
  }

  signals.stopClicked.listener = () => {
    controller.abort();
  };
};


const activatePanelWithData = () => {
  // find the first panel in panelState.value that has data.tool_uid == '4447bd6c-9ade-47c3-891b-2d88576a2ec7'
  const panel = Object.values(panelState.value).find(panel => panel.data?.tool_uid === '4447bd6c-9ade-47c3-891b-2d88576a2ec7')
  console.log('activatePanelWithData', panel)
  if (panel) {
    setSwitchToPanel(panel.data.panel_id)
  }
  
}

const includeDataOpen = ref(false)

const availableDataSources = computed(() => {
  const sources = []
  
  // Tools to exclude from chat
  const excludedTools = ['visualise']
  
  // Mapping for chat menu labels
  const chatLabels = {
    'nameStatus': 'Names',
    'biosecurityStatus': 'Biosecurity',
    'availabilityStatus': 'Stock',
    'diversityStatus': 'Diversity',
    // Add tool label mappings if needed
  }
  
  // Add all available tools that have data
  Object.values(panelState.value || {}).forEach(panel => {
    if (panel.data?.tool_uid) {
      const tool = tools.find(t => t.uid === panel.data.tool_uid)
      if (tool && !sources.find(s => s.id === tool.uid) && !excludedTools.includes(tool.id)) {
        sources.push({
          id: tool.uid,
          name: chatLabels[tool.id] || tool.label,
          icon: tool.icon,
          type: 'tool'
        })
      }
    }
  })
  
  // Add validations if available
  const projectValidations = validationStore.allValidations[route.params.project]
  if (projectValidations) {
    if (projectValidations.nameStatus) {
      sources.push({
        id: 'nameStatus',
        name: chatLabels.nameStatus,
        icon: validationIcons.nameStatus,
        type: 'validation'
      })
    }
    if (projectValidations.biosecurityStatus) {
      sources.push({
        id: 'biosecurityStatus',
        name: chatLabels.biosecurityStatus,
        icon: validationIcons.biosecurityStatus,
        type: 'validation'
      })
    }
    if (projectValidations.availabilityStatus) {
      sources.push({
        id: 'availabilityStatus',
        name: chatLabels.availabilityStatus,
        icon: validationIcons.availabilityStatus,
        type: 'validation'
      })
    }
    if (projectValidations.diversityStatus) {
      sources.push({
        id: 'diversityStatus',
        name: chatLabels.diversityStatus,
        icon: validationIcons.diversityStatus,
        type: 'validation'
      })
    }
  }
  
  return sources
})

const chatData = computed(() => {
  const panelData = panelState.value[props.panel_id]
  return panelData?.data?.output
})

const getAnalysisData = (panel) => {
  return panel.data?.output || panel.output || panel.tables || panel.data?.output
}

const getMapData = (panel) => {
  return panel.data?.output
}

</script>

<template>
  <transition :css="false" @leave="(el, done) => motions['chat_modal'].leave(done)">
    <KeepAlive>
      <div ref="chatDialogRef" v-motion="`chat_modal`" :initial="{ opacity: 0, x: 100 }"
        :enter="{ opacity: 1, x: 0, transition: { duration: 50 } }"
        :leave="{ opacity: 0, x: 100, transition: { duration: 50 } }" v-if="chatDialogOpen == true"
        :class="['overflow-hidden absolute right-[4vw] sm:right-[2vw] bottom-[18vw] sm:bottom-[2vw] w-[450px] h-[50vh] rounded-[1.65em] border-[1px] border-primary/10 transition-all duration-450 ease-out bg-background/70', { 'shadow-xxl backdrop-blur-md': !chatDialogPinned, 'shadow-lg backdrop-blur-sm border-primary/20': chatDialogPinned }, $attrs.class]">

        <!-- <GlowBox class="z-10 pointer-events-none flex flex-col absolute w-full rounded-[1.65em]"></GlowBox> -->

        <div class="group flex w-full h-full p-0">
          <div v-if="!isLoaded" class="flex h-full w-full justify-center items-center p-4 aspect-square flex-col gap-8">
            <Button @click="loadChat" variant="default" class="p-2 rounded-full p-2 aspect-square">
              <!-- user firnedly disclaimer about ai -->
              <Icon name="material-symbols:power-settings-new-outline-rounded" class="w-6 h-6" />
            </Button>
            <p class="text-xs text-muted-foreground text-center">
                SuperSeeded chat can make mistakes.<br/>Check important info.
              </p>
          </div>
          <ClientOnly>
            <deep-chat v-if="windowHeight && isLoaded" :onNewMessage="handleNewMessage" ref="deepChatRef" id="deepchat"
              :submitButtonStyles="submitButtonStyles" :chatStyle="chatStyle" :introMessage="introMessage"
              :avatars="avatars" :messageStyles="messageStyles" :inputAreaStyle="inputAreaStyle" :textInput="textInput"
              :auxiliaryStyle="auxiliaryStyle" :requestBodyLimits="{ maxMessages: 0, totalMessagesMaxCharLength: 0 }"
              :connect="{ url: `${base_host}/api/sse`, stream: true, additionalBodyProps: { history }, handler: handler }"
              :history="history" />

          </ClientOnly>

          <div class="flex absolute top-0 w-full justify-end p-0">
            <Button @click="togglePinChatDialog()"
              :class="['left', {
                'opacity-100 md:opacity-0':
                  !chatDialogPinned
              }, 'sm:group-hover:opacity-100  relative transition-all duration-250 ease-out', { 'rotate-45': !chatDialogPinned }]"
              variant="text">
              <Icon name="bi:pin-fill" size="18"
                :class="{ 'text-muted-foreground/30': !chatDialogPinned, 'text-primary/80': chatDialogPinned }" />
            </Button>
          </div>
          <div v-if="!tosAccepted"
            class="flex absolute pointer-events-all w-full bottom-12 w-[90%] text-center justify-center items-center p-0 rounded-full text-xs z-[1000]">
            <p class="text-xs text-muted-foreground whitespace-nowrap select-none">
              By chatting you agree to our <NuxtLink to="/legal/privacy" class="underline" target="_blank">Privacy Policy
              </NuxtLink>
            </p>
            <Button @click="acceptTos" class="pointer-events-all rounded-full px-0 ml-1" variant="icon">
              <Icon name="material-symbols:close" size="15" />
            </Button>
          </div>

          <div class="hidden flex absolute bottom-2 justify-center items-center">
            <Input class="rounded-full text-xs bg-transparent border-2 border-primary/10"
              placeholder="Send a message" />
            <Button class="rounded-full px-0 mx-2" variant="text">
              <Icon name="fluent:arrow-circle-up-28-filled" size="20" />
            </Button>
          </div>

          <div v-if="isLoaded" class="flex pointer-events-all absolute bottom-0 w-fit justify-start p-2 z-[1001]">
            <DropdownMenu v-model:open="includeDataOpen">
              <DropdownMenuTrigger as-child>
                <Toggle 
                  :disabled="!currentTool"
                  :pressed="includeDataInChat"
                  @click="activatePanelWithData"
                  :class="[
                    'group/toggle rounded-full aspect-square w-10 h-10 p-1 relative -top-1 left-1', 
                    { 
                      'bg-gradient-to-r from-orange-500 to-purple-500': includeDataInChat,
                      '!cursor-default': !currentTool
                    }
                  ]"
                >
                  <Icon 
                    name="solar:paperclip-bold" 
                    size="20"
                    class="transform -rotate-45"
                    :class="{ 
                      'text-white': includeDataInChat, 
                      'text-muted-foreground/50 group-hover/toggle:text-primary': !includeDataInChat 
                    }" 
                  />
                </Toggle>
                <div
                  v-if="selectedSources.size > 0"
                  class="pointer-events-none absolute top-0 left-2 flex h-4 w-4 items-center align-center justify-center rounded-full bg-primary text-[10px] font-bold text-primary-foreground transition-all duration-200 z-10"
                >
                  {{ selectedSources.size }}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent 
                class="rounded-xl ring ring-1 ring-muted-foreground/10 shadow-none pr-3" 
                side="top" 
                align="start"
              >
                <DropdownMenuItem
                  v-for="source in availableDataSources"
                  :key="source.id"
                  class="m-1 flex items-center justify-between w-full px-3 py-2 hover:bg-muted/50 rounded-md"
                  @click="() => {
                    toggleSource(source.id);
                    includeDataOpen = false;
                  }"
                >
                  <div class="flex items-center">
                    <Icon 
                      :name="source.icon" 
                      size="1.2em"
                      class="mr-2"
                    />
                    {{ source.name }}
                  </div>
                  <Icon v-if="selectedSources.has(source.id)"
                    name="ic:baseline-done"
                    size="1.2em"
                    class="ml-4"
                  />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>





        </div>
      </div>
    </KeepAlive>
  </transition>


  <transition :css="false" @leave="(el, done) => motions['buttonAnim'].leave(done)">
    <RainbowButton 
      v-motion="`buttonAnim`" 
      :initial="{ opacity: 0, x: -100 }"
      :enter="{ opacity: 1, x: 0, transition: { duration: 250, ease: 'cubicBezier(0.25, 0.1, 0.25, 1.0)' } }"
      :leave="{ opacity: 0, x: -100, transition: { duration: 50 } }" 
      v-if="chatDialogOpen == false"
      @click="toggleChatDialog(true)" 
      class="absolute rounded-full shadow-xxl right-[4vw] bottom-[18vw] sm:bottom-[4vw] bg-primary/90 hover:bg-primary/75"
      circular
    >
      <Icon name="solar:chat-line-linear" class="text-background" size="20" />
    </RainbowButton>
  </transition>
</template>

<style>

#deepchat {
  height: calc(50vh - 30px) !important;
}



</style>  